import logo from './logo.svg';
import './App.css';
import LandingPageEn from './LandingPageEn';
import LandingPageAr from './LandingPageAr';
import { QueryClient, QueryClientProvider } from 'react-query';

function App() {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <LandingPageEn />
      {/* <LandingPageAr /> */}
    </QueryClientProvider>
  )
}

export default App;
