import { Alert, Button, Loader, LoadingOverlay } from '@mantine/core';
import React, { useState } from 'react'
import OtpInput from 'react-otp-input';
import { validateEmail, validatePhone } from './functions';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js'
import { useMutation } from 'react-query';
import { APIKEY, HOST } from './static';
import axios from 'axios'


const LandingPageEn = () => {
    const [formData, setFormData] = useState();
    const [error, setError] = useState()
    const [errorVisible, setErrorVisible] = useState(false)
    const [otp, setOtp] = useState('');
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false)


    const handleChange = ({ target }) => {
        setErrorVisible(false)
        setFormData({ ...formData, [target.name]: target.value })
    }


    const submitOtp = useMutation(
        (data) =>
            axios.post(`${HOST}/submit-otp`, {
                phone: data,
            }, {
                headers: {
                    Authorization: `Bearer ${APIKEY}`,
                }
            }),
        {
            onSuccess: async (data, variables, context) => {
                setLoading(false)
                setStep(2)
            },
            onError: () => {
                setLoading(false);
                setError('An error has occured. Please retry.')
                setErrorVisible(true)
            }
        },
    );

    const verifyOtp = useMutation(
        (data) =>
            axios.post(`${HOST}/verify-otp`, {
                phone: data.phone,
                code: data.otp
            },  {
                headers: {
                    Authorization: `Bearer ${APIKEY}`,
                }
            }),
        {
            onSuccess: async (data, variables, context) => {
                setLoading(false)
                setStep(3)
            },
            onError: (error) => {
                if (error.toString().includes('410')) {
                    setLoading(false);
                    setError('The code you entered is wrong. Please retry.')
                    setErrorVisible(true)
                } else {
                    setLoading(false);
                    setError('Something went wrong. Please retry.')
                    setErrorVisible(true)
                }

            }
        },
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!formData?.name || !formData?.phone || !formData?.email) {
            setError('All the fields are required');
            setErrorVisible(true)
        } else if (!validateEmail(formData?.email)) {
            setError('Invalid email. Please verify.');
            setErrorVisible(true)
        } else if (!validatePhone(formData?.phone)) {
            setError('Invalid phone number. Please verify. \n Phone numbers must be valid UAE phone numbers');
            setErrorVisible(true)
        } else {
            setErrorVisible(false)
            setLoading(true)
            submitOtp.mutate(formData?.phone)
        }
    }

    const handleVerify = (event) => {
        event.preventDefault();
        setErrorVisible(false)
        setLoading(true)
        verifyOtp.mutate({ phone: formData?.phone, otp: otp })
    }
    return (
        <>
            <link href="css/css-assets.css" rel="stylesheet" />
            <link href="css/style.css" rel="stylesheet" />
            <div id="full-container">
                <header id="header">
                    <div id="header-bar-1" className="header-bar">
                        <div className="header-bar-wrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="hb-content">
                                            <a className="logo logo-header" href="index.html">
                                                <h2>
                                                    <span style={{ color: 'white', marginTop: 30 }}>
                                                        Your company
                                                    </span>
                                                </h2>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <section id="banner">
                    <div className="banner-parallax" data-banner-height="650">
                        <div className="overlay-colored color-bg-dark opacity-85"></div>
                        <div className="slide-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="banner-center-box text-white">
                                            <span className="subheading">We Craft Stuff</span>
                                            <h1>
                                                <span className="heading-200">We Do </span>
                                                Professional Things
                                                <span className="heading-200"> to Grow Your Business</span>
                                            </h1>
                                            <p className="description">
                                                We care to deliver and craft adaptive services and
                                                solutions to our customers to achieve and satisfy their
                                                needs. We always take attention to every details within
                                                work.
                                            </p>
                                            <div className="btn-row mt-20">
                                                <small> We Hope to Listen From You Soon </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-md-push-1">
                                        <div className="banner-center-box">
                                            <div
                                                className="cta-subscribe cta-subscribe-1 box-form text-center"
                                            >
                                                <div className="box-title">
                                                    <span className="icon icon-Megaphone"></span>
                                                    {step == 1 ? <h3>Get Started Today</h3> : step == 2 ? <h3>Pleased to meet you {formData?.name} !</h3> : <h3>Thank you !</h3>}
                                                    {step == 1 ? <p>
                                                        Everything you need to grow your business. Order our
                                                        services today!
                                                    </p> : step == 2 ?
                                                        <p>Please fill this OTP with the number you received by SMS on this number {formData?.phone}</p> :
                                                        <p>We will reach out to you shortly !</p>
                                                    }
                                                </div>
                                                <div className="box-content">
                                                    {<LoadingOverlay visible={loading} />}
                                                    {errorVisible &&
                                                        <Alert withCloseButton onClose={() => setErrorVisible(false)} style={{ marginBottom: 10 }} title="Error!" color="red" >
                                                            <p style={{ textAlign: 'left', color: 'red', fontSize: 13 }}>{error}</p>
                                                        </Alert>}
                                                    {step == 1 && <form id="form-cta-subscribe-1">
                                                        <div className="form-group">
                                                            <span className="field-icon icon icon-User"></span>
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                className="form-control"
                                                                placeholder="Enter your name"
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <span className="field-icon icon icon-Mail"></span>
                                                            <input
                                                                type="text"
                                                                name="email"
                                                                id="email"
                                                                className="form-control"
                                                                placeholder="Enter your email address"
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <span className="field-icon icon icon-Phone2"></span>
                                                            <input
                                                                type="text"
                                                                name="phone"
                                                                id="phone"
                                                                className="form-control"
                                                                placeholder="Enter a valid UAE phone number"
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <input
                                                                type="submit"
                                                                className="form-control"
                                                                value="Submit"
                                                                onClick={handleSubmit}
                                                            />
                                                        </div>
                                                    </form>}
                                                    {step == 2 && <div className="box-center">
                                                        <OtpInput
                                                            containerStyle={{ justifyContent: 'center' }}
                                                            value={otp}
                                                            onChange={setOtp}
                                                            numInputs={4}
                                                            inputStyle={{ width: 80, fontWeight: 'bold', fontSize: 20 }}
                                                            renderSeparator={<span> - </span>}
                                                            renderInput={(props) => <input {...props} />}
                                                        />
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                                                            <input
                                                                type="submit"
                                                                className="form-control"
                                                                value="Validate my phone number"
                                                                onClick={handleVerify}
                                                            />
                                                        </div>
                                                        <Button size='lg' style={{ marginTop: 20 }} onClick={() => setStep(1)}>Back</Button>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div id="section-our-services-1" className="section-flat">
                    <div className="section-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 col-md-push-2">
                                    <h2 className="title-featured-1 text-center">
                                        <span className="heading-200"
                                        >We Always Take Attention to Every Details Within Work. We
                                            Care to Deliver and Craft</span
                                        >
                                        Adaptive Services
                                        <span className="heading-200"
                                        >and Solutions to Our Customers to Achieve and</span
                                        >
                                        Satisfy Their Needs.
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="section-cta-1"
                    className="section-parallax center-vertical text-white"
                >
                    <div className="overlay-colored color-bg-dark opacity-85"></div>
                    <div className="section-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="box-center">
                                        <h1>
                                            <span className="heading-200">We Are Waiting</span>
                                            <br />
                                            Let's Get Started Now!
                                        </h1>
                                        <p className="description">
                                            We care to deliver adaptive services and solutions to our
                                            customers to achieve and satisfy their needs.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="box-center">
                                        <div className="btn-row style-2 mt-md-50">
                                            <a
                                                className="btn x-large colorful hover-dark rounded move-top"
                                                href="#"
                                            >Let's Start</a
                                            >
                                            <small>
                                                We Hope to Listen
                                                <br />
                                                From You Soon
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer id="footer">
                    <div id="footer-bar-1" className="footer-bar">
                        <div className="footer-bar-wrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="fb-row">
                                            <div className="copyrights-message">
                                                <span>Copyright © 2023</span>
                                                <a href="javascript:;" target="_blank">The Pubverse.</a>
                                                <span>All Rights Reserved.</span>
                                            </div>
                                            <ul
                                                className="social-icons x4 grey-bg hover-colorful-bg animated rounded"
                                            >
                                                <li>
                                                    <a className="si-twitter" href="#"
                                                    ><i className="fa fa-twitter"></i
                                                    ><i className="fa fa-twitter"></i
                                                    ></a>
                                                </li>
                                                <li>
                                                    <a className="si-facebook" href="#"
                                                    ><i className="fa fa-facebook"></i
                                                    ><i className="fa fa-facebook"></i
                                                    ></a>
                                                </li>
                                                <li>
                                                    <a className="si-youtube" href="#"
                                                    ><i className="fa fa-youtube"></i
                                                    ><i className="fa fa-youtube"></i
                                                    ></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default LandingPageEn